@font-face {
  font-family: "Century Gothic";
  src:  local("CenturyGothic"),
    url("../fonts/CenturyGothic.woff2") format("woff2"),
    url("../fonts/CenturyGothic.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic";
  src: local("CenturyGothic-Bold"),
    url("../fonts/CenturyGothic-Bold.woff2") format("woff2"),
    url("../fonts/CenturyGothic-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "PintaninaPro";
  src:url("../fonts/PintaninaPro.woff2") format("woff2"),
    url("../fonts/PintaninaPro.woff") format("woff");
  font-weight: 400;
  font-style: normal
}
