.btn {
  background-color: transparent;
  color: #000;
  line-height: 1;
  display: inline-block;
  padding: .3em .5em;
  vertical-align: bottom;
  cursor: pointer;
  white-space: nowrap;
  border: 2px solid #000;
  font-family: PintaninaPro, sans-serif;
  font-size: 2em;
  transition-property: background-color,color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  box-shadow: 20px 38px 34px -26px rgba(0,0,0,.2);
  border-radius: 255px 15px 225px/15px 225px 15px 255px;
  text-align: center;

  &:hover {
    text-decoration: none;
    background-color: #000;
    color: #fff;
  }
}
