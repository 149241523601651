.social {
  @include list;
  display: flex;
  margin-left: auto;

  &--footer {
    flex-direction: row;
    align-self: center;
    margin-left: 0;
    width: 100%;
    justify-content: space-between;

    .social__svg {
      width: 29px;
      height: 28px;
    }
  }
}

.social__item {
  &:not(:last-child) {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.social__link {
  @include center-flex;

  &:hover {
    .st1 {
      fill: #F6BE2D;
    }
  }
}

.social__img {
  width: 36px;
  height: 36px;
}

@media screen and (min-width: 768px) {
  .social {
    &--footer {
      width: auto;
    }
  }
  .social__svg {
    width: 29px;
    height: 28px;
  }
}

@media screen and (min-width: 1024px) {
  .social {
    flex-direction: row;
    align-self: center;
  }
}
