.banner-smm {
  padding: 20px;
  padding-top: 220px;
  margin-top: -218px;
  background-color: #F4F9FF;
}

.banner-smm__title {
  font-size: 44px;
  line-height: 54px;
  font-weight: 700;
}

.banner-smm__text {
  font-size: 28px;
  line-height: 32px;
}

.banner-smm__btn {
  display: block;
  max-width: 284px;
  margin: auto;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #000;
  text-decoration: none;
  background-color: #F6BE2D;
  box-shadow: 0 3px 5px #b6984c;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff9100;
  }
}

@media screen and (min-width: 768px) {
  .banner-smm {
    padding: 400px 20px 100px;
    text-align: center;
    background-image: url("../img/smm-banner.png");
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  .banner-smm__title {
    font-size: 46px;
    margin-bottom: 0;
  }

  .banner-smm__text {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}
