.footer {
  padding: 50px 0 20px;
  background-color: #f7f4ef;
}

.footer__content {
  padding: 5px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer__link-list {
  @include list;
}

.footer__link {
  color: #000000;
  font-size: 24px;
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .footer__content {
    @include container;
    padding: 10px 15px;
  }

  .footer__link {
    font-size: 34px;
  }

  .footer__link-item {
    margin: 10px 0;
  }
}
