.start {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 30px 20px;
}

.start__title {
  margin: 15px 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.start__text {
  font-size: 18px;
  line-height: 30px;
}

.start__img-box {
  position: relative;
  grid-area: 1/1/2/3;
  margin-left: auto;
  margin-bottom: 10px;
}

.start__discription {
  grid-area: 2/1/3/3;
}

@media screen and (min-width: 768px) {
  .start {
    @include container;
    padding: 30px 0;
    grid-template-rows: 20% 70% 10%;
  }

  .start__title {
    font-size: 36px;
  }

  .start__discription {
    padding-top: 30px;
    grid-area: 2/1/3/2;
  }

  .start__img-box {
    grid-area: 1/2/4/3;
  }
}

@media screen and (min-width: 1200px) {
  .start__img-box {
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: -30px;
      left: -50px;
      right: -1000px;
      bottom: -20px;
      background-color: #f7f4ef;
    }
}
}
