.expand__title {
  margin: 15px 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

.expand__text {
  font-size: 18px;
  line-height: 30px;
}

.expand__description {
  padding: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .expand{
    img {
      margin: auto;
    }
  }
}

@media screen and (min-width: 1024px) {
  .expand{
    @include container;
    display: flex;
    align-items: center;
    padding: 50px 0;
  }

  .expand__description {
    margin-right: auto;
    max-width: 585px;
    padding: 40px 20px;
  }

  .expand__title {
    font-size: 36px;
    line-height: 40px;
  }
}
