@mixin list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin container {
  max-width: 1170px;
  margin: auto;
}
