.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -90px;
  margin-left: -160px;

  &--show {
    display: block;
  }
}

.modal__video {
  width: 320px;
  height: 180px;
}

.modal__close {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-image: url("../img/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
}

@media screen and (min-width: 768px) {
  .modal {
    margin-top: -180px;
    margin-left: -320px;
  }

  .modal__video {
    width: 640px;
    height: 360px;
  }
}
