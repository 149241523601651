.seller {
  padding: 30px 0;
}

.seller__text-content {
  max-width: 585px;
  padding: 20px;
}

.seller__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
}

.seller__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px;
}

@media screen and (min-width: 1024px) {
  .seller {
    @include container;
    padding: 80px 0;
    display: flex;
    align-items: center;
  }

  .seller__text-content {
    padding: 40px 20px;
  }

  .seller__img {
    position: relative;
    order: 1;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 60px;
      right: -1000px;
      bottom: 0;
      background-color: #f5f5f5;
    }
  }
}
