.header__content {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header__logo {
  margin-right: auto;
}

@media screen and (min-width: 1024px) {
  .header__content {
    @include container;
    padding: 10px 15px;
  }
}
