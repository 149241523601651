@font-face {
  font-family: "Century Gothic";
  src: local("CenturyGothic"), url("../fonts/CenturyGothic.woff2") format("woff2"), url("../fonts/CenturyGothic.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Century Gothic";
  src: local("CenturyGothic-Bold"), url("../fonts/CenturyGothic-Bold.woff2") format("woff2"), url("../fonts/CenturyGothic-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "PintaninaPro";
  src: url("../fonts/PintaninaPro.woff2") format("woff2"), url("../fonts/PintaninaPro.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

html {
  line-height: 1.15;
  /* 1 */
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

body {
  min-width: 320px;
  margin: 0;
  font-family: "Century Gothic", "Tahoma", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  overflow-x: hidden; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

.callback__form .modal {
  display: none; }

@-webkit-keyframes a {
  0% {
    opacity: 0;
    -webkit-transform: translate(-130px);
            transform: translate(-130px); }
  to {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes a {
  0% {
    opacity: 0;
    -webkit-transform: translate(-130px);
            transform: translate(-130px); }
  to {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@-webkit-keyframes b {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes b {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes c {
  0% {
    opacity: 0;
    -webkit-transform: translate(130px);
            transform: translate(130px); }
  to {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes c {
  0% {
    opacity: 0;
    -webkit-transform: translate(130px);
            transform: translate(130px); }
  to {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@-webkit-keyframes d {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes d {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes e {
  0% {
    -webkit-box-shadow: 0 0 1px 0 #ffea00,inset 0 0 1px 0 #ffea00;
            box-shadow: 0 0 1px 0 #ffea00,inset 0 0 1px 0 #ffea00; }
  to {
    -webkit-box-shadow: 0 0 3px 1px #ffea00,inset 0 0 3px 1px #ffea00;
            box-shadow: 0 0 3px 1px #ffea00,inset 0 0 3px 1px #ffea00; } }

@keyframes e {
  0% {
    -webkit-box-shadow: 0 0 1px 0 #ffea00,inset 0 0 1px 0 #ffea00;
            box-shadow: 0 0 1px 0 #ffea00,inset 0 0 1px 0 #ffea00; }
  to {
    -webkit-box-shadow: 0 0 3px 1px #ffea00,inset 0 0 3px 1px #ffea00;
            box-shadow: 0 0 3px 1px #ffea00,inset 0 0 3px 1px #ffea00; } }

@-webkit-keyframes f {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

@keyframes f {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

@-webkit-keyframes g {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0); }
  to {
    -webkit-transform: translate3d(10%, 10%, 0) rotateY(50deg);
            transform: translate3d(10%, 10%, 0) rotateY(50deg); } }

@keyframes g {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0); }
  to {
    -webkit-transform: translate3d(10%, 10%, 0) rotateY(50deg);
            transform: translate3d(10%, 10%, 0) rotateY(50deg); } }

@-webkit-keyframes h {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
  to {
    -webkit-transform: translateZ(-50px);
            transform: translateZ(-50px); } }

@keyframes h {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
  to {
    -webkit-transform: translateZ(-50px);
            transform: translateZ(-50px); } }

@-webkit-keyframes back {
  0% {
    background-image: url("../images/background.webp"); }
  to {
    background-image: url("../images/back2.webp"); } }

@keyframes back {
  0% {
    background-image: url("../images/background.webp"); }
  to {
    background-image: url("../images/back2.webp"); } }

.page-section.with-background-image.is-image-1 {
  background-image: url("../images/section-1-bg.png");
  background-repeat: no-repeat;
  background-position: center left; }

.page-section.is-main-banner {
  position: relative;
  background-image: url("../images/background.webp");
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-color: #6b645e;
  /*animation: 1s ease 3s both back;*/ }

.page-section.is-main-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../images/back2.webp");
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  -webkit-animation: 1s ease 5s both d;
          animation: 1s ease 5s both d; }

.page-section__inner {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1182px; }

.btn.is-main-on-banner, button.is-main-on-banner {
  position: absolute;
  bottom: 2rem;
  right: 0;
  border-color: #ffea00;
  color: #ffea00;
  -webkit-animation: .7s linear infinite alternate e,.5s ease-in 7s both d;
          animation: .7s linear infinite alternate e,.5s ease-in 7s both d;
  font-size: 2.5em;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-width: 3px;
  letter-spacing: .1em;
  -webkit-box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
          box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
  border-radius: 255px 15px 225px/15px 225px 15px 255px; }

.banner {
  position: relative;
  height: 540px;
  display: none;
  padding: 0; }

.banner__question {
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50%;
  text-indent: -9999px;
  overflow: hidden;
  display: block; }

.banner__question.is-question-0 {
  top: 5%;
  left: 30%;
  width: 176px;
  height: 136px;
  background-image: url("../images/head6.png");
  -webkit-animation: .4s ease-in 5s both d;
          animation: .4s ease-in 5s both d; }

.banner__question.is-question-1 {
  top: 10%;
  left: 0;
  width: 292px;
  height: 93px;
  background-image: url("../images/head1.png");
  -webkit-animation: .7s ease-in .7s both a;
          animation: .7s ease-in .7s both a; }

.banner__question.is-question-2 {
  top: 30%;
  left: 0;
  width: 307px;
  height: 245px;
  background-image: url("../images/head2.png");
  -webkit-animation: .7s ease-in 1.2s both b;
          animation: .7s ease-in 1.2s both b; }

.banner__question.is-question-3 {
  top: 10%;
  right: 0;
  width: 271px;
  height: 154px;
  background-image: url("../images/head3.png");
  -webkit-animation: .7s ease-in 1.8s both b;
          animation: .7s ease-in 1.8s both b; }

.banner__question.is-question-4 {
  top: 50%;
  right: 0;
  width: 265px;
  height: 55px;
  background-image: url("../images/head4.png");
  -webkit-animation: .7s ease-in 2.2s both c;
          animation: .7s ease-in 2.2s both c; }

.banner__question.is-question-5 {
  top: 62%;
  right: 80px;
  width: 125px;
  height: 103px;
  background-image: url("../images/head5.png");
  -webkit-animation: .4s ease-in 7.2s both d;
          animation: .4s ease-in 7.2s both d; }

.banner img {
  width: 100%; }

.banner__content {
  font-size: 2em;
  padding: 1em 0 1em 5px;
  max-width: 17em; }

.banner.is-header {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 100%; }

@media screen and (min-width: 1024px) {
  .banner {
    display: block; } }

.btn {
  background-color: transparent;
  color: #000;
  line-height: 1;
  display: inline-block;
  padding: .3em .5em;
  vertical-align: bottom;
  cursor: pointer;
  white-space: nowrap;
  border: 2px solid #000;
  font-family: PintaninaPro, sans-serif;
  font-size: 2em;
  -webkit-transition-property: background-color,color;
  transition-property: background-color,color;
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
          box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
  border-radius: 255px 15px 225px/15px 225px 15px 255px;
  text-align: center; }
  .btn:hover {
    text-decoration: none;
    background-color: #000;
    color: #fff; }

.header__content {
  padding: 5px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.header__logo {
  margin-right: auto; }

@media screen and (min-width: 1024px) {
  .header__content {
    max-width: 1170px;
    margin: auto;
    padding: 10px 15px; } }

.contacts--footer {
  padding-top: 15px; }

.contacts__phone {
  display: block;
  padding: 5px 0 5px 38px;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  background-image: url("../img/phone.svg");
  background-repeat: no-repeat;
  background-position: center left; }
  .contacts__phone--footer {
    color: #000000; }

.contacts__adress {
  max-width: 110px;
  margin: 10px 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-align: center; }
  .contacts__adress--footer {
    color: #000000; }

@media screen and (min-width: 768px) {
  .contacts__phone {
    font-size: 34px; }
  .contacts__adress {
    max-width: none; } }

.social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto; }
  .social--footer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-item-align: center;
        align-self: center;
    margin-left: 0;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .social--footer .social__svg {
      width: 29px;
      height: 28px; }

.social__item:not(:last-child) {
  margin-bottom: 0;
  margin-right: 20px; }

.social__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .social__link:hover .st1 {
    fill: #F6BE2D; }

.social__img {
  width: 36px;
  height: 36px; }

@media screen and (min-width: 768px) {
  .social--footer {
    width: auto; }
  .social__svg {
    width: 29px;
    height: 28px; } }

@media screen and (min-width: 1024px) {
  .social {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-item-align: center;
        align-self: center; } }

.navi {
  position: relative;
  z-index: 10; }
  .navi--fix {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; }

.navi__btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 30px;
  width: 100%;
  background-color: #222222;
  font-family: "Century Gothic", "Tahoma", sans-serif;
  font-size: 16px;
  color: #fff;
  border: none;
  outline: none; }

.navi__list {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 0 25px;
  display: none;
  background-color: #fff; }
  .navi__list--active {
    display: block; }

.navi__item {
  position: relative; }

.navi__link {
  display: block;
  padding: 7px 25px;
  color: #000000;
  font-size: 18px;
  text-decoration: none;
  border-bottom: 5px solid transparent;
  -webkit-transition: border-bottom 0.3s ease;
  transition: border-bottom 0.3s ease; }
  .navi__link--active, .navi__link:hover {
    border-bottom: 5px solid #f6be2d;
    text-decoration: none; }

.navi__sub {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 30px;
  background-color: white;
  border-radius: 0 0 5px 5px; }

.navi__sub-item {
  padding: 5px 12px; }

.navi__sub-link {
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  border-bottom: 2px solid transparent; }

.navi__sub-link:hover {
  text-decoration: none;
  border-bottom: 2px solid #f6be2d; }

@media screen and (min-width: 1024px) {
  .navi {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white; }
    .navi--smm {
      background-color: #f4f9ff; }
    .navi--dir {
      background-color: #eef1f8; }
  .navi__btn {
    display: none; }
  .navi__list {
    max-width: 1170px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 25px 0;
    background-color: transparent; }
  .navi__link {
    padding: 10px; }
  .navi__sub {
    display: none;
    position: absolute;
    z-index: 1;
    padding-left: 0; }
  .navi__item:hover .navi__sub {
    display: block; }
  .navi__sub:hover {
    display: block; }
  .navi__sub-item {
    padding: 10px 12px; }
  .navi__sub-link {
    border-bottom: 2px solid transparent; }
  .navi__sub-link:hover {
    text-decoration: none;
    border-bottom: 2px solid #f6be2d; } }

.content--main {
  background-image: url("../images/section-1-bg.png");
  background-repeat: no-repeat;
  background-position: top 40px left; }

.content__list {
  margin: 0;
  padding: 0;
  list-style: none; }

.content__item {
  padding-top: 100px; }

.content__img {
  max-height: 220px;
  margin: auto; }

.content__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px; }

.content__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px; }

.content__text-box {
  padding: 0 20px; }

@media screen and (min-width: 768px) {
  .content__list {
    max-width: 700px;
    margin: auto; } }

@media screen and (min-width: 1024px) {
  .content--main {
    background-position: top 200px left; }
  .content__list {
    max-width: 1170px;
    margin: auto; }
  .content__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .content__img {
    max-height: none;
    margin-left: 160px; }
  .content__text-box {
    max-width: 650px; }
    .content__text-box--1 {
      max-width: 700px; }
    .content__text-box--left {
      -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
              order: -1; } }

.banner {
  padding: 20px; }

.banner__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.banner__title {
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px; }
  .banner__title--span {
    color: #daa00c; }

.banner__text {
  color: #565656;
  font-size: 16px;
  line-height: 22px; }

.banner__btn {
  display: block;
  padding: 20px 30px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  -webkit-box-shadow: 0 3px 5px #b6984c;
          box-shadow: 0 3px 5px #b6984c;
  background-color: #f6be2d; }

.banner__img-box {
  -ms-flex-preferred-size: 330px;
      flex-basis: 330px;
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: -70px; }

@media screen and (min-width: 768px) {
  .banner__title {
    max-width: 520px;
    font-size: 46px;
    line-height: 58px; }
  .banner__text {
    font-size: 23px;
    line-height: 28px; }
  .banner__img-box {
    margin-left: auto; } }

@media screen and (min-width: 1024px) {
  .banner {
    padding: 120px 20px 80px; }
  .banner__content {
    max-width: 1170px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%; }
  .banner__img-box {
    grid-area: 1/2/5/2; }
  .banner__btn {
    max-width: 220px;
    margin-top: 30px;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease; }
    .banner__btn:hover {
      background-color: #ff9100; } }

.for {
  margin-top: 20px; }

.for__title {
  margin: 15px 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px; }

.for__text {
  font-size: 18px;
  line-height: 30px; }

.for__description {
  padding: 30px 20px; }

@media screen and (min-width: 768px) {
  .for {
    max-width: 1170px;
    margin: auto;
    padding: 80px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .for__title {
    font-size: 36px; }
  .for__img-box {
    max-width: none;
    grid-area: 1/1/4/2; }
  .for__description {
    max-width: 650px;
    margin-left: auto;
    padding: 40px 20px; } }

.start {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 30px 20px; }

.start__title {
  margin: 15px 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px; }

.start__text {
  font-size: 18px;
  line-height: 30px; }

.start__img-box {
  position: relative;
  grid-area: 1/1/2/3;
  margin-left: auto;
  margin-bottom: 10px; }

.start__discription {
  grid-area: 2/1/3/3; }

@media screen and (min-width: 768px) {
  .start {
    max-width: 1170px;
    margin: auto;
    padding: 30px 0;
    grid-template-rows: 20% 70% 10%; }
  .start__title {
    font-size: 36px; }
  .start__discription {
    padding-top: 30px;
    grid-area: 2/1/3/2; }
  .start__img-box {
    grid-area: 1/2/4/3; } }

@media screen and (min-width: 1200px) {
  .start__img-box::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -30px;
    left: -50px;
    right: -1000px;
    bottom: -20px;
    background-color: #f7f4ef; } }

.portfolio {
  padding: 30px 0; }

.portfolio__title {
  padding: 0 10px;
  text-align: center;
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px; }
  .portfolio__title--span {
    color: #d9a00c; }

.portfolio__list {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.portfolio__item {
  position: relative;
  margin: 10px;
  -webkit-box-shadow: 0px 0px 25px rgba(150, 149, 149, 0.49);
          box-shadow: 0px 0px 25px rgba(150, 149, 149, 0.49); }
  .portfolio__item:hover .portfolio__description {
    height: 100%;
    opacity: 1; }

.portfolio__description {
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 0;
  padding: 20px;
  color: #fff;
  background-color: #000000c4;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  opacity: 0;
  overflow: hidden;
  text-align: center; }
  .portfolio__description h3 {
    width: 100%; }

.portfolio__link {
  display: block; }

.portfolio__btn {
  display: block;
  padding: 12px 20px;
  max-width: 200px;
  margin: auto;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  -webkit-box-shadow: 0 3px 5px #b6984c;
          box-shadow: 0 3px 5px #b6984c;
  background-color: #f6be2d;
  border: none;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease; }
  .portfolio__btn:hover {
    background-color: #ff9100; }

@media screen and (min-width: 768px) {
  .portfolio {
    max-width: 1170px;
    margin: auto;
    padding: 80px 0 20px; }
  .portfolio__title {
    max-width: 710px;
    margin: auto;
    font-size: 36px;
    line-height: 50px; }
    .portfolio__title--10 {
      max-width: none; }
  .portfolio__item {
    width: 29%;
    margin: 15px; } }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  max-width: 380px;
  width: 100%;
  padding: 0 20px 30px; }

.card__title {
  margin: 0;
  color: #1d1d1d;
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  text-align: center; }

.card__text {
  font-size: 18px;
  line-height: 30px; }

.card__btn {
  position: relative;
  display: block;
  margin: auto auto 0;
  padding: 0 0 10px;
  font-size: 18px;
  font-weight: 700;
  color: #d9a00c;
  text-decoration: none; }
  .card__btn::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #F6BE2D;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease; }
  .card__btn:hover {
    text-decoration: none; }
    .card__btn:hover::before {
      bottom: 5px; }

.card__img-link {
  display: block;
  margin: auto; }
  .card__img-link img {
    -webkit-box-shadow: 0 3px 5px #9a9a9a;
            box-shadow: 0 3px 5px #9a9a9a;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease; }

@media screen and (min-width: 768px) {
  .card {
    width: 48%;
    max-width: none; } }

@media screen and (min-width: 1024px) {
  .card {
    width: 31%; } }

.card__img-link:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.callback {
  padding: 0 20px 70px;
  max-width: 600px;
  margin: auto; }

.callback__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.callback__label {
  width: 50%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-right: 10px; }

.callback__input {
  width: 100%;
  padding: 10px;
  font-size: 20px;
  border: 2px solid #000;
  font-family: PintaninaPro,sans-serif;
  background-color: transparent;
  -webkit-box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
          box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
  border-radius: 255px 15px 225px/15px 225px 15px 255px; }

.callback__btn {
  font-size: 18px;
  line-height: 20px;
  padding: 5px 10px; }

.expand__title {
  margin: 15px 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px; }

.expand__text {
  font-size: 18px;
  line-height: 30px; }

.expand__description {
  padding: 20px;
  margin-bottom: 20px; }

@media screen and (min-width: 768px) {
  .expand img {
    margin: auto; } }

@media screen and (min-width: 1024px) {
  .expand {
    max-width: 1170px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 50px 0; }
  .expand__description {
    margin-right: auto;
    max-width: 585px;
    padding: 40px 20px; }
  .expand__title {
    font-size: 36px;
    line-height: 40px; } }

.send {
  position: relative;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-image: url("../img/send-img.png");
  background-repeat: no-repeat;
  background-position: top 280px right;
  background-origin: content-box;
  background-color: #f7f4ef; }
  .send::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50px;
    left: -1000px;
    right: 370px;
    bottom: 50px;
    background-color: #f7f4ef; }

.send__title {
  max-width: 415px;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px; }

.send__input {
  min-width: 274px;
  padding: 15px;
  margin: 10px 0;
  color: #4c4c4c;
  font-family: "Century Gothic";
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #030202;
  outline: none; }

.send__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 274px;
  height: 61px;
  margin: 150px 0 50px;
  padding: 20px 30px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  border: none;
  -webkit-box-shadow: 0 3px 5px #b6984c;
          box-shadow: 0 3px 5px #b6984c;
  background-color: #f6be2d; }

@media screen and (min-width: 768px) {
  .send {
    margin-bottom: 100px;
    background-position: top 200px right 150px; }
  .send__title {
    max-width: 765px;
    font-size: 36px;
    line-height: 57px; }
  .send__btn {
    margin: 50px 0; } }

@media screen and (min-width: 1024px) {
  .send {
    max-width: 1170px;
    margin: auto;
    padding: 50px 380px 50px 0;
    margin-bottom: 100px;
    margin-top: 60px;
    background-position: top 200px right 150px;
    background-clip: content-box; }
  .send__title {
    font-size: 36px;
    line-height: 57px; }
  .send__btn {
    margin: 50px 0;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease; }
    .send__btn:hover {
      background-color: #ff9100; } }

.footer {
  padding: 50px 0 20px;
  background-color: #f7f4ef; }

.footer__content {
  padding: 5px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.footer__link-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.footer__link {
  color: #000000;
  font-size: 24px;
  text-decoration: none; }

@media screen and (min-width: 1024px) {
  .footer__content {
    max-width: 1170px;
    margin: auto;
    padding: 10px 15px; }
  .footer__link {
    font-size: 34px; }
  .footer__link-item {
    margin: 10px 0; } }

.banner-10000 {
  padding: 20px; }

.banner-10000__title {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #222222; }
  .banner-10000__title span {
    color: #daa00c; }

.banner-10000__text {
  font-size: 18px; }

@media screen and (min-width: 1024px) {
  .banner-10000 {
    max-width: 1170px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 50px 0; }
  .banner-10000__img {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .banner-10000__content {
    max-width: 780px; }
  .banner-10000__title {
    font-size: 46px;
    line-height: 52px; }
  .banner-10000__text {
    margin-top: 20px;
    font-size: 23px; } }

.banner-3500__content {
  padding: 20px; }

.banner-3500__title {
  margin-top: 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #222222; }
  .banner-3500__title span {
    color: #daa00c; }

.banner-3500__text {
  font-size: 18px; }

@media screen and (min-width: 1024px) {
  .banner-3500 {
    background-image: url("../img/back-banner.png");
    background-repeat: no-repeat; }
  .banner-3500__content {
    max-width: 1170px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 50px 0; }
  .banner-3500__text-content {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
    max-width: 780px; }
  .banner-3500__title {
    font-size: 46px;
    line-height: 52px; }
  .banner-3500__text {
    margin-top: 20px;
    font-size: 23px;
    line-height: 30px; } }

.banner-video {
  max-width: 1170px;
  margin: auto; }
  .banner-video::after {
    content: "";
    display: block;
    margin: auto;
    width: 140px;
    height: 119px;
    background-image: url("../img/arroy-down.svg");
    background-repeat: no-repeat;
    background-position: center; }

.banner-video__btn {
  display: block;
  max-width: 220px;
  padding: 20px;
  margin: 30px auto;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  background-color: #F6BE2D;
  border: none;
  text-decoration: none;
  text-align: center; }
  .banner-video__btn:hover {
    background-color: #ffc224; }

.what {
  padding: 30px 0; }

.what__text-content {
  max-width: 585px;
  padding: 20px; }

.what__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px; }

.what__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px; }

@media screen and (min-width: 1024px) {
  .what {
    max-width: 1170px;
    margin: auto;
    padding: 50px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .what__text-content {
    padding: 40px 20px;
    margin-left: auto; } }

.seller {
  padding: 30px 0; }

.seller__text-content {
  max-width: 585px;
  padding: 20px; }

.seller__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px; }

.seller__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px; }

@media screen and (min-width: 1024px) {
  .seller {
    max-width: 1170px;
    margin: auto;
    padding: 80px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .seller__text-content {
    padding: 40px 20px; }
  .seller__img {
    position: relative;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
    .seller__img::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 60px;
      right: -1000px;
      bottom: 0;
      background-color: #f5f5f5; } }

.doing {
  padding: 30px 0; }

.doing__text-content {
  max-width: 585px;
  padding: 20px; }

.doing__title {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px; }

.doing__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px; }

@media screen and (min-width: 1024px) {
  .doing {
    max-width: 1170px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .doing__text-content {
    padding: 40px 20px;
    margin-left: auto; }
  .doing__title {
    font-size: 34px;
    line-height: 40px; } }

.study {
  padding: 30px 0; }

.study__text-content {
  max-width: 550px;
  padding: 20px; }

.study__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px; }

.study__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px; }

@media screen and (min-width: 1024px) {
  .study {
    max-width: 1170px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .study__text-content {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
    padding: 40px 20px;
    margin-right: auto; }
  .study__title {
    font-size: 34px; } }

.more {
  padding: 30px 0; }

.more__text-content {
  max-width: 585px;
  padding: 20px; }

.more__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px; }

.more__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px; }

@media screen and (min-width: 1024px) {
  .more {
    max-width: 1170px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .more__text-content {
    padding: 40px 20px;
    margin-left: auto; }
  .more__title {
    font-size: 34px; } }

.form {
  position: relative;
  padding: 20px;
  margin: 50px auto;
  background-color: #F7F4EF; }

.form__title {
  width: 100%;
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  text-align: center; }

.form__select {
  width: 100%;
  padding: 12px;
  margin: 5px 0;
  border: none;
  font-family: "Century Gothic", "Tahoma", sans-serif;
  background-color: #fff; }

.form__input {
  width: 100%;
  padding: 11px;
  margin-bottom: 10px;
  border: none;
  font-family: "Century Gothic", "Tahoma", sans-serif; }
  .form__input::-webkit-input-placeholder {
    color: #C1C1C1; }
  .form__input::-moz-placeholder {
    color: #C1C1C1; }
  .form__input:-ms-input-placeholder {
    color: #C1C1C1; }
  .form__input::-ms-input-placeholder {
    color: #C1C1C1; }
  .form__input::placeholder {
    color: #C1C1C1; }

.form__label {
  font-size: 14px;
  line-height: 14px; }
  .form__label--question {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 10px 0;
    min-height: 48px;
    font-size: 18px;
    line-height: 24px; }
    .form__label--question span {
      padding-right: 15px;
      font-size: 36px;
      font-weight: 700;
      color: #F6BE2D; }
  .form__label--repeat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 5px 0;
    font-size: 12px;
    line-height: 10px;
    color: #A0A0A0; }
    .form__label--repeat span {
      border-bottom: 1px dashed; }

.form__repeat-check {
  margin-right: 10px;
  width: 14px;
  height: 14px;
  background-color: #fff; }

.form__repeat-input:checked ~ .form__repeat-check {
  background-image: url("../img/check.svg");
  background-repeat: no-repeat;
  background-position: center; }

.form__questions {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px; }

.form__radios {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.form__radios-item {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  .form__radios-item:not(:last-child) {
    margin-right: 10px; }

.form__radio-label {
  display: block;
  width: 100%;
  height: 40px;
  margin: 10px 0 0;
  background-image: url("../img/mail.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: -3px -3px 10px 0 rgba(255, 255, 255, 0.83), 3px 3px 10px 0 rgba(217, 210, 200, 0.51);
          box-shadow: -3px -3px 10px 0 rgba(255, 255, 255, 0.83), 3px 3px 10px 0 rgba(217, 210, 200, 0.51);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease; }
  .form__radio-label--whatsapp {
    background-image: url("../img/whatsapp.svg"); }
  .form__radio-label--viber {
    background-image: url("../img/viber.svg"); }
  .form__radio-label--telegram {
    background-image: url("../img/telegram.svg"); }
  .form__radio-label span {
    display: none; }

.form__radio:checked ~ .form__radio-label {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #F6BE2D; }

.form__inputs {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 20px 0; }

.form__inputs-item--tel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.form__inputs-item--hide {
  display: none; }

.form__text {
  width: 100%;
  padding: 15px;
  min-height: 100px;
  resize: none;
  border: none;
  font-family: "Century Gothic", "Tahoma", sans-serif; }
  .form__text::-webkit-input-placeholder {
    color: #C1C1C1; }
  .form__text::-moz-placeholder {
    color: #C1C1C1; }
  .form__text:-ms-input-placeholder {
    color: #C1C1C1; }
  .form__text::-ms-input-placeholder {
    color: #C1C1C1; }
  .form__text::placeholder {
    color: #C1C1C1; }

.form__agree {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px 0; }

.form__agree-text {
  margin-left: 15px;
  font-size: 14px;
  line-height: 14px; }

.form__btn {
  display: block;
  width: 100%;
  padding: 20px 30px;
  margin: 20px 0;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  -webkit-box-shadow: 0 3px 5px #b6984c;
          box-shadow: 0 3px 5px #b6984c;
  background-color: #f6be2d;
  border: none;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease; }
  .form__btn:hover {
    background-color: #ff9100; }

.form__agree-link {
  color: #000;
  text-decoration: none; }

@media screen and (min-width: 768px) {
  .form {
    max-width: 600px; }
  .form__inputs-item--tel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .form__inputs-item--hide {
    display: none; } }

@media screen and (min-width: 1024px) {
  .form {
    max-width: 1170px;
    margin: 80px auto 100px;
    padding: 40px 20px 60px; }
  .form__wrapper {
    max-width: 680px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .form__questions-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .form__select {
    max-width: 300px;
    margin-left: auto; }
  .form__questions {
    width: 100%; }
  .form__box {
    width: 360px; }
  .form__box2 {
    width: 300px; }
  .form__text {
    min-height: 150px; }
  .form__title {
    font-size: 36px;
    line-height: 38px; }
  .form__label--repeat {
    margin-left: auto; } }

@-webkit-keyframes draw {
  0% {
    opacity: 1;
    stroke-dashoffset: -99.47578430175781;
    fill: transparent;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  35% {
    stroke-dashoffset: 0;
    fill: transparent; }
  60% {
    fill: #3da35a;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    fill: #3da35a;
    stroke-dashoffset: 0;
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

@keyframes draw {
  0% {
    opacity: 1;
    stroke-dashoffset: -99.47578430175781;
    fill: transparent;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  35% {
    stroke-dashoffset: 0;
    fill: transparent; }
  60% {
    fill: #3da35a;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    fill: #3da35a;
    stroke-dashoffset: 0;
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

.success {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 280px;
  margin-left: -140px;
  padding: 20px;
  background-color: #c5e1a5;
  border-radius: 10px; }

.success__title {
  font-size: 30px;
  font-weight: 700; }

.success__svg {
  position: absolute;
  top: 10px;
  right: 20px; }

.success__svg path {
  stroke-dasharray: 99.47578430175781;
  stroke-dashoffset: -99.47578430175781;
  fill: transparent; }

.success__svg.animate path {
  -webkit-animation: 1.7s ease forwards draw;
          animation: 1.7s ease forwards draw;
  opacity: 1; }

@media screen and (min-width: 768px) {
  .success {
    width: 500px;
    margin-left: -250px; } }

@media screen and (min-width: 1024px) {
  .success {
    height: 200px;
    margin-top: -100px; } }

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: -webkit-transform 400ms;
  transition: -webkit-transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.navi__btn--activ .hamRotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.line {
  fill: none;
  -webkit-transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #fff;
  stroke-width: 5.5;
  stroke-linecap: round; }

.ham5 .top {
  stroke-dasharray: 40 82; }

.ham5 .bottom {
  stroke-dasharray: 40 82; }

.navi__btn--activ .ham5 .top {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px; }

.navi__btn--activ .ham5 .bottom {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px; }

.video-portfolio__title {
  padding: 0 15px;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-align: center; }

.video-portfolio__list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 780px;
  margin: auto; }

.video-portfolio__item {
  position: relative;
  max-width: 370px;
  padding: 1px;
  margin: 10px auto;
  background-color: #37474F; }

.video-portfolio__play {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  display: block;
  width: 100px;
  height: 100px;
  background-color: #F6BE2D;
  border-radius: 50%;
  background-image: url("../img/play.png");
  background-repeat: no-repeat;
  background-position: center left 33px; }
  .video-portfolio__play:hover {
    background-color: #ffc224; }

@media screen and (min-width: 768px) {
  .video-portfolio__title {
    padding: 20px 30px 50px;
    background-image: url("../img/title-back.svg");
    background-repeat: no-repeat;
    background-position: center bottom; }
  .video-portfolio__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .video-portfolio__item {
    width: 50%; } }

@media screen and (min-width: 1200px) {
  .video-portfolio__list {
    max-width: 1170px;
    margin: auto; } }

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -90px;
  margin-left: -160px; }
  .modal--show {
    display: block; }

.modal__video {
  width: 320px;
  height: 180px; }

.modal__close {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-image: url("../img/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none; }

@media screen and (min-width: 768px) {
  .modal {
    margin-top: -180px;
    margin-left: -320px; }
  .modal__video {
    width: 640px;
    height: 360px; } }

.banner-smm {
  padding: 20px;
  padding-top: 220px;
  margin-top: -218px;
  background-color: #F4F9FF; }

.banner-smm__title {
  font-size: 44px;
  line-height: 54px;
  font-weight: 700; }

.banner-smm__text {
  font-size: 28px;
  line-height: 32px; }

.banner-smm__btn {
  display: block;
  max-width: 284px;
  margin: auto;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #000;
  text-decoration: none;
  background-color: #F6BE2D;
  -webkit-box-shadow: 0 3px 5px #b6984c;
          box-shadow: 0 3px 5px #b6984c;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease; }
  .banner-smm__btn:hover {
    background-color: #ff9100; }

@media screen and (min-width: 768px) {
  .banner-smm {
    padding: 400px 20px 100px;
    text-align: center;
    background-image: url("../img/smm-banner.png");
    background-repeat: no-repeat;
    background-position: bottom center; }
  .banner-smm__title {
    font-size: 46px;
    margin-bottom: 0; }
  .banner-smm__text {
    margin-top: 10px;
    margin-bottom: 50px; } }

.banner-dir {
  margin-top: -218px;
  background-color: #eef1f8; }

.banner-dir__wrapper {
  padding: 20px;
  padding-top: 220px; }

.banner-dir__title {
  font-size: 44px;
  line-height: 54px;
  font-weight: 700; }

.banner-dir__text {
  font-size: 28px;
  line-height: 32px; }

.banner-dir__btn {
  display: block;
  max-width: 284px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #000;
  text-decoration: none;
  background-color: #F6BE2D;
  -webkit-box-shadow: 0 3px 5px #b6984c;
          box-shadow: 0 3px 5px #b6984c;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease; }
  .banner-dir__btn:hover {
    background-color: #ff9100; }

@media screen and (min-width: 768px) {
  .banner-dir__wrapper {
    padding: 350px 20px 100px;
    background-image: url("../img/banner-direct.jpg");
    background-repeat: no-repeat;
    background-position: bottom right; }
  .banner-dir__content {
    max-width: 480px; }
  .banner-dir__title {
    font-size: 46px;
    margin-bottom: 0; }
  .banner-dir__text {
    margin-top: 10px; } }

@media screen and (min-width: 1024px) {
  .banner-dir__wrapper {
    padding: 300px 20px 100px;
    max-width: 1170px;
    margin: auto; }
  .banner-dir__text {
    margin-bottom: 50px; } }

.need {
  max-width: 540px;
  margin: auto; }

.need__content {
  padding: 20px; }

.need__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 30px; }

.need__text {
  font-size: 18px;
  line-height: 24px; }

@media screen and (min-width: 768px) {
  .need {
    max-width: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .need__content {
    max-width: 520px; }
    .need__content--left {
      -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
              order: -1; }
  .need__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px; } }

@media screen and (min-width: 1024px) {
  .need {
    max-width: 1200px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 100px 0 50px; }
  .need__content {
    max-width: 570px; } }

.works__title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  text-align: center; }

.works__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.works__item {
  margin-bottom: 20px; }

@media screen and (min-width: 768px) {
  .works__title {
    font-size: 36px;
    line-height: 44px; } }

@media screen and (min-width: 1024px) {
  .works__list {
    max-width: 1200px;
    margin: auto; }
  .works__title {
    margin-bottom: 50px; }
  .works__item {
    margin: 10px; } }
