.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  line-height: 1.15; /* 1 */
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  font-family: "Century Gothic", "Tahoma", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  overflow-x: hidden;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

//фикс callback

.callback__form {
  .modal {
    display: none;
  }
}
