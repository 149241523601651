.navi {
  position: relative;
  z-index: 10;

  &--fix {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
}

.navi__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  width: 100%;
  background-color: #222222;
  font-family: "Century Gothic", "Tahoma", sans-serif;
  font-size: 16px;
  color: #fff;
  border: none;
  outline: none;
}

.navi__list {
  @include list;
  padding: 0 25px;
  display: none;
  background-color: #fff;

  &--active {
    display: block;
  }
}

.navi__item {
  position: relative;
}

.navi__link {
  display: block;
  padding: 7px 25px;
  color: #000000;
  font-size: 18px;
  text-decoration: none;
  border-bottom: 5px solid transparent;
  transition: border-bottom 0.3s ease;

  &--active,
  &:hover {
    border-bottom: 5px solid #f6be2d;
    text-decoration: none;
  }
}

.navi__sub {
  @include list;
  padding-left: 30px;
  background-color: white;
  border-radius: 0 0 5px 5px;
}

.navi__sub-item {
  padding: 5px 12px;
}

.navi__sub-link {
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.navi__sub-link:hover {
  text-decoration: none;
  border-bottom: 2px solid #f6be2d;
}

@media screen and (min-width: 1024px) {
  .navi {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;

    &--smm {
      background-color: #f4f9ff;
    }

    &--dir {
      background-color: #eef1f8;
    }
  }

  .navi__btn {
    display: none;
  }

  .navi__list {
    @include container;
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    background-color: transparent;
  }

  .navi__link {
    padding: 10px;
  }

  .navi__sub {
    display: none;
    position: absolute;
    z-index: 1;
    padding-left: 0;
  }

  .navi__item:hover{

    .navi__sub {
      display: block;
    }
  }

  .navi__sub:hover {
    display: block;
  }

  .navi__sub-item {
    padding: 10px 12px;
  }

  .navi__sub-link {
    border-bottom: 2px solid transparent;
  }

  .navi__sub-link:hover {
    text-decoration: none;
    border-bottom: 2px solid #f6be2d;
  }
}

