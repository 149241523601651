.card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  max-width: 380px;
  width: 100%;
  padding: 0 20px 30px;
}

.card__title {
  margin: 0;
  color: #1d1d1d;
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
}

.card__text {
  font-size: 18px;
  line-height: 30px;
}

.card__btn {
  position: relative;
  display: block;
  margin: auto auto 0;
  padding: 0 0 10px;
  font-size: 18px;
  font-weight: 700;
  color: #d9a00c;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #F6BE2D;
    transition: 0.3s ease;
  }

  &:hover {
    text-decoration: none;

    &::before {
      bottom: 5px;
    }
  }
}

.card__img-link {
  display: block;
  margin: auto;

  img {
    box-shadow: 0 3px 5px #9a9a9a;
    transition: 0.3s ease;
  }
}

@media screen and (min-width: 768px) {
  .card {
    width: 48%;
    max-width: none;
  }
}

@media screen and (min-width: 1024px) {
  .card {
    width: 31%;
  }
}

.card__img-link {

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}
