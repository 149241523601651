@keyframes a {
    0% {
        opacity: 0;
        transform: translate(-130px)
    }

    to {
        opacity: 1;
        transform: translate(0)
    }
}

@keyframes b {
    0% {
        opacity: 0;
        transform: scale(.6)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes c {
    0% {
        opacity: 0;
        transform: translate(130px)
    }

    to {
        opacity: 1;
        transform: translate(0)
    }
}

@keyframes d {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes e {
    0% {
        box-shadow: 0 0 1px 0 #ffea00,inset 0 0 1px 0 #ffea00
    }

    to {
        box-shadow: 0 0 3px 1px #ffea00,inset 0 0 3px 1px #ffea00
    }
}

@keyframes f {
    0% {
        transform: rotate(0)
    }

    to {
        transform: rotate(1turn)
    }
}

@keyframes g {
    0% {
        transform: translateZ(0) rotateY(0)
    }

    to {
        transform: translate3d(10%,10%,0) rotateY(50deg)
    }
}

@keyframes h {
    0% {
        transform: translateZ(0)
    }

    to {
        transform: translateZ(-50px)
    }
}

@keyframes back {
    0% {
        background-image: url("../images/background.webp");
    }

    to {
        background-image: url("../images/back2.webp");
    }
}

.page-section.with-background-image.is-image-1 {
    background-image: url("../images/section-1-bg.png");
    background-repeat: no-repeat;
    background-position: center left;
}

.page-section.is-main-banner {
    position: relative;
    background-image: url("../images/background.webp");
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-color: #6b645e;
    /*animation: 1s ease 3s both back;*/
}

.page-section.is-main-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/back2.webp");
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    animation: 1s ease 5s both d;
}

.page-section__inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 1182px
}

.btn.is-main-on-banner,button.is-main-on-banner {
    position: absolute;
    bottom: 2rem;
    right: 0;
    border-color: #ffea00;
    color: #ffea00;
    animation: .7s linear infinite alternate e,.5s ease-in 7s both d;
    font-size: 2.5em;
    box-shadow: none;
    border-width: 3px;
    letter-spacing: .1em;
    box-shadow: 20px 38px 34px -26px rgba(0,0,0,.2);
    border-radius: 255px 15px 225px/15px 225px 15px 255px
}

.banner {
    position: relative;
    height: 540px;
    display: none;
    padding: 0;
}

.banner__question {
    position: absolute;
    background-repeat: no-repeat;
    background-position: 50%;
    text-indent: -9999px;
    overflow: hidden;
    display: block
}

.banner__question.is-question-0 {
    top: 5%;
    left: 30%;
    width: 176px;
    height: 136px;
    background-image: url("../images/head6.png");
    animation: .4s ease-in 5s both d
}

.banner__question.is-question-1 {
    top: 10%;
    left: 0;
    width: 292px;
    height: 93px;
    background-image: url("../images/head1.png");
    animation: .7s ease-in .7s both a
}

.banner__question.is-question-2 {
    top: 30%;
    left: 0;
    width: 307px;
    height: 245px;
    background-image: url("../images/head2.png");
    animation: .7s ease-in 1.2s both b
}

.banner__question.is-question-3 {
    top: 10%;
    right: 0;
    width: 271px;
    height: 154px;
    background-image: url("../images/head3.png");
    animation: .7s ease-in 1.8s both b
}

.banner__question.is-question-4 {
    top: 50%;
    right: 0;
    width: 265px;
    height: 55px;
    background-image: url("../images/head4.png");
    animation: .7s ease-in 2.2s both c
}

.banner__question.is-question-5 {
    top: 62%;
    right: 80px;
    width: 125px;
    height: 103px;
    background-image: url("../images/head5.png");
    animation: .4s ease-in 7.2s both d
}

.banner img {
    width: 100%
}

.banner__content {
    font-size: 2em;
    padding: 1em 0 1em 5px;
    max-width: 17em
}

.banner.is-header {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 100%
}

@media screen and (min-width: 1024px) {
    .banner {
        display: block;
    }
}
