.portfolio {
  padding: 30px 0;
}

.portfolio__title {
  padding: 0 10px;
  text-align: center;
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;

  &--span {
    color: #d9a00c;
  }
}

.portfolio__list {
  @include list;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.portfolio__item {
  position: relative;
  margin: 10px;
  box-shadow: 0px 0px 25px rgba(150, 149, 149, 0.49);

  &:hover {
    .portfolio__description {
      height: 100%;
      opacity: 1;
    }
  }
}

.portfolio__description {
  position: absolute;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 0;
  padding: 20px;
  color: #fff;
  background-color: #000000c4;
  transition: 0.3s ease;
  opacity: 0;
  overflow: hidden;
  text-align: center;

  h3 {
    width: 100%;
  }
}

.portfolio__link {
  display: block;
}

.portfolio__btn {
  display: block;
  padding: 12px 20px;
  max-width: 200px;
  margin: auto;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  box-shadow: 0 3px 5px #b6984c;
  background-color: #f6be2d;
  border: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff9100;
  }
}

@media screen and (min-width: 768px) {
  .portfolio {
    @include container;
    padding: 80px 0 20px;
  }

  .portfolio__title {
    max-width: 710px;
    margin: auto;
    font-size: 36px;
    line-height: 50px;

    &--10 {
      max-width: none;
    }
  }

  .portfolio__item {
    width: 29%;
    margin: 15px;
  }
}
