.form {
  position: relative;
  padding: 20px;
  margin: 50px auto;
  background-color: #F7F4EF;
}

.form__title {
  width: 100%;
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
}

.form__select {
  width: 100%;
  padding: 12px;
  margin: 5px 0;
  border: none;
  font-family: "Century Gothic", "Tahoma", sans-serif;
  background-color: #fff;
}

.form__input {
  width: 100%;
  padding: 11px;
  margin-bottom: 10px;
  border: none;
  font-family: "Century Gothic", "Tahoma", sans-serif;

  &::placeholder {
    color: #C1C1C1;
  }
}

.form__label {
  font-size: 14px;
  line-height: 14px;

  &--question {
    display: flex;
    align-items: center;
    margin: 10px 0;
    min-height: 48px;
    font-size: 18px;
    line-height: 24px;

    span {
      padding-right: 15px;
      font-size: 36px;
      font-weight: 700;
      color: #F6BE2D;
    }
  }

  &--repeat {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 12px;
    line-height: 10px;
    color: #A0A0A0;


    span {
      border-bottom: 1px dashed;
    }
  }
}

.form__repeat-check {
  margin-right: 10px;
  width: 14px;
  height: 14px;
  background-color: #fff;
}

.form__repeat-input:checked ~ .form__repeat-check {
  background-image: url("../img/check.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.form__questions {
  @include list;
  margin-bottom: 20px;
}

.form__radios {
  @include list;
  display: flex;
}

.form__radios-item {
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.form__radio-label {
  display: block;
  width: 100%;
  height: 40px;
  margin: 10px 0 0;
  background-image: url("../img/mail.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  border: 1px solid rgba(255,255,255,0.2);
  box-shadow: -3px -3px 10px 0 rgba(255,255,255,0.83),
    3px 3px 10px 0 rgba(217,210,200,0.51);
  transition: 0.3s ease;

  &--whatsapp {
    background-image: url("../img/whatsapp.svg");
  }

  &--viber {
    background-image: url("../img/viber.svg");
  }

  &--telegram {
    background-image: url("../img/telegram.svg");
  }

  span {
    display: none;
  }
}

.form__radio:checked ~ .form__radio-label {
  border: 1px solid rgba(0,0,0,0.05);
  background-color: #F6BE2D;
}

.form__inputs {
  @include list;
  margin: 20px 0;
}

.form__inputs-item {

  &--tel {
    display: flex;
    flex-wrap: wrap;
  }

  &--hide {
    display: none;
  }
}

.form__text {
  width: 100%;
  padding: 15px;
  min-height: 100px;
  resize: none;
  border: none;
  font-family: "Century Gothic", "Tahoma", sans-serif;

  &::placeholder {
    color: #C1C1C1;
  }
}

.form__agree {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.form__agree-text {
  margin-left: 15px;
  font-size: 14px;
  line-height: 14px;
}

.form__btn {
  display: block;
  width: 100%;
  padding: 20px 30px;
  margin: 20px 0;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0 3px 5px #b6984c;
  background-color: #f6be2d;
  border: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff9100;
  }
}

.form__agree-link {
  color: #000;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .form {
    max-width: 600px;
  }

  .form__inputs-item {

    &--tel {
      display: flex;
      flex-wrap: wrap;
    }

    &--hide {
      display: none;
    }
  }
}

@media screen and (min-width: 1024px) {
  .form {
    max-width: 1170px;
    margin: 80px auto 100px;
    padding: 40px 20px 60px;
  }

  .form__wrapper {
    max-width: 680px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form__questions-item {
    display: flex;
    align-items: center;
  }

  .form__select {
    max-width: 300px;
    margin-left: auto;
  }

  .form__questions {
    width: 100%;
  }

  .form__box {
    width: 360px;
  }

  .form__box2 {
    width: 300px;
  }

  .form__text {
    min-height: 150px;
  }

  .form__title {
    font-size: 36px;
    line-height: 38px;
  }

  .form__label--repeat {
    margin-left: auto;
  }
}
