.study {
  padding: 30px 0;
}

.study__text-content {
  max-width: 550px;
  padding: 20px;
}

.study__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.study__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 32px;
}

@media screen and (min-width: 1024px) {
  .study {
    @include container;
    display: flex;
    align-items: center;
  }

  .study__text-content {
    order: -1;
    padding: 40px 20px;
    margin-right: auto;
  }

  .study__title {
    font-size: 34px;
  }
}
