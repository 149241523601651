.for {
  margin-top: 20px;
}

.for__title {
  margin: 15px 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.for__text {
  font-size: 18px;
  line-height: 30px;
}

.for__description {
  padding: 30px 20px;
}

@media screen and (min-width: 768px) {
  .for {
    @include container;
    padding: 80px 0;
    display: flex;
    align-items: center;
  }

  .for__title {
    font-size: 36px;
  }

  .for__img-box {
    max-width: none;
    grid-area: 1/1/4/2;
  }

  .for__description {
    max-width: 650px;
    margin-left: auto;
    padding: 40px 20px;
  }
}
